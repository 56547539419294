<template>
  <Nav />
  <Menu />
  <main class="app-main">
    <router-view />
  </main>
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
// https://github.com/ColorlibHQ/AdminLTE
import { Nav, Menu, Footer } from './components';

export default defineComponent({
  name: 'App',
  components: {
    Nav,
    Menu,
    Footer,
  },
});
</script>
